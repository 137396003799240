import { makeHooksConfig } from '@virtuslab/nfs-shared/src/config/hooks';
import type { RequiredDocuments } from '@virtuslab/nfs-shared/src/config/hooks';
import {
  useUserProjectsColorsProviderAllProjectsQuery,
  useUserTimeReportBlockedMessageSpaceOwnerQuery,
  useUserTimeReportOnCallDetailsModalQuery,
  useUserTimeReportFinancesLazyQuery,
  UserProjectsColorsProviderAllProjectsDocument,
  useUserBenefitSectionsAssignedBenefitVariantsQuery,
  useUserTimeReportEntryModalLimitsQuery,
  useUserInvoicesDrawerCreateInvoiceMutation,
  useUserInvoicesDrawerRemoveInvoiceMutation,
  useUserInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery,
  UserTimeReportEntryModalLimitsDocument,
  useUserTimeReportLeaveAndTrainingQuery,
  useUserDirectReportingStructureQuery,
  useUserTimeReportStatsLazyQuery,
} from '@virtuslab/nfs-shared/src/schema/user';

export const documents: RequiredDocuments = {
  userProjectsIds: UserProjectsColorsProviderAllProjectsDocument,
  timeReportEntryModalLimits: UserTimeReportEntryModalLimitsDocument,
};

export const hooks = makeHooksConfig({
  useOnCallMultipliers: useUserTimeReportOnCallDetailsModalQuery,
  useProjectColors: useUserProjectsColorsProviderAllProjectsQuery,
  useUserSpaceOwner: useUserTimeReportBlockedMessageSpaceOwnerQuery,
  useTimeReportFinances: useUserTimeReportFinancesLazyQuery,
  useBenefitSectionsAssignedBenefitVariantsQuery: useUserBenefitSectionsAssignedBenefitVariantsQuery,
  useTimeReportEntryModalLimitsQuery: useUserTimeReportEntryModalLimitsQuery,
  useInvoicesDrawerUpdateInvoiceMutation: useUserInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerRemoveInvoiceMutation: useUserInvoicesDrawerRemoveInvoiceMutation,
  useInvoicesDrawerCreateInvoiceMutation: useUserInvoicesDrawerCreateInvoiceMutation,
  useTimeReportLeaveAndTrainingQuery: useUserTimeReportLeaveAndTrainingQuery,
  useInvoicesDrawerInvoicesLazyQuery,
  useTimeReportStats: useUserTimeReportStatsLazyQuery,
  useDirectReportingStructureQuery: useUserDirectReportingStructureQuery,
});
